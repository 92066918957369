import './App.css';
import Home from './sections/Home';
import CorpoClinico from './sections/CorpoClinico';
import Menu from './sections/Menu';
import Footer from './sections/Footer';
import { Helmet } from 'react-helmet';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Contactos from './sections/Contactos';
import Servicos from './sections/Servicos';

function App() {
    return (
        <div className="App">
            <Helmet>
                <title>
                    Medicina da Mulher
                </title>
            </Helmet>

            <div className="body">
                <Router>
                    <Menu/>
                    <Switch>
                        <Route exact path='/'>
                            <Home/>
                        </Route>
                        <Route exact path='/corpo-clinico'>
                            <CorpoClinico/>
                        </Route>
                        <Route exact path='/servicos'>
                            <Servicos/>
                        </Route>
                        <Route exact path='/contactos'>
                            <Contactos/>
                        </Route>
                    </Switch>
                </Router>
                <Footer/>

            </div>
            <div className="logo">
                <img src="./static/LogoMM.svg" width="75%" height="auto" alt="logo"/>
            </div>
            <div className="video">
                <img className="photo" src='./static/fallback.png' alt="bgFallback"/>
                {/*<video loop autoPlay >*/}
                {/*  <source src='./static/walk.mp4' type="video/mp4" />*/}
                {/*  <img src='./static/fallback.png' alt="bgFallback" /> */}
                {/*  </video>*/}

            </div>
        </div>
    );
}
export default App;
