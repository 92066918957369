import { Link, useHistory, useLocation,  } from "react-router-dom";
import { React, useState } from 'react'

const Menu = () => {
    const {pathname} = useLocation();
    const {push} = useHistory();
    const getPath = (path) => {
        if (['home', 'corpo-clinico', 'servicos', 'contactos', ''].includes(path)) {
            return path;
        } else {
            push('/');
        }
    }
    
    const [selected, setSelected] = useState(getPath(pathname.slice(1)) );

    return (
        <div className="menu">
            <Link to='/'
                className={selected === 'home' ? 'selected' : ''}
                onClick={() => setSelected('home')}>A Medicina da Mulher</Link>
            <Link to='/corpo-clinico'
                className={selected === 'corpo-clinico' ? 'selected' : ''}
                onClick={() => setSelected('corpo-clinico')}>Corpo Clínico</Link>
            <Link to='/servicos'
                className={selected === 'servicos' ? 'selected' : ''}
                onClick={() => setSelected('servicos')}>Serviços</Link>
            <Link to='/contactos'
                className={selected === 'contactos' ? 'selected' : ''}
                onClick={() => setSelected('contactos')}>Contactos</Link>
        </div>)
}

export default Menu;