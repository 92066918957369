import { Helmet } from "react-helmet";

const Contactos = () => (
    <div className="section">
        <Helmet>
            <title>Contactos - Medicina da Mulher</title>
        </Helmet>

        <h3>Morada</h3>
        <p>Edificio Cruzeiro<br></br>
            Av. Calouste Gulbenkian, nº 4 - 1º Andar, Sala 14<br></br>
            3000-090 Coimbra</p>
        <h4>E-mail</h4>
        <p><a href="mailto:geral@medicinadamulher.pt">geral@medicinadamulher.pt</a></p>
        <h4>Telefone</h4>
        <p><a href="tel:239841100">239 841 100</a></p>
    </div>
)

export default Contactos;
