import {Helmet} from "react-helmet";

const Servicos = () => (
    <div style={{textAlign: 'left', marginLeft: '25vw'}}>
        <Helmet>
            <title>Serviços - Medicina da Mulher</title>
        </Helmet>

        <h1>Serviços</h1>
        <p>
            Consultas de Obstetrícia
        </p>
        <p>
            Consultas de Ginecologia
        </p>
        <p>
            Ecografias Obstétricas
            (1º, 2º e 3º Trimestres, Morfológicas, Precoces e 3D)
        </p>
        <p>
            Ecografias Ginecológicas
        </p>
        <p>
            Introdução e Remoção de Dispositivos Intra-Uterinos
        </p>
        <p>
            Colposcopia
        </p>
    </div>)


export default Servicos;
